<template>
  <div>
    <div v-if="campaignOptions.length > 0">
      <!-- Campaign, Date Picker, Update Dashboard -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="domain-info mb-8">
            <!-- Campaign -->
            <select-filter
              labelname="Campaign"
              :options="campaignOptions"
              optionsName="Campaign_Name"
              :value="camapign"
              @input="newDomain => {camapign = newDomain;}"
            ></select-filter>
            <!-- Date Picker -->
            <date-range-picker
              :fromDateValue="fromDate"
              :toDateValue="toDate"
              @inputFromDate="newFromDate => {fromDate = newFromDate;}"
              @inputToDate="newToDate => {toDate = newToDate;}"
            ></date-range-picker>
            <!-- Update Dashboard -->
            <vs-button type="filled" class="small-btn bg-actionbutton" @click="updateData()">{{LabelConstant.buttonLabelUpdateDashboard}}</vs-button>
          </div>
        </div>
      </div>
      <!-- Average Score, Total Leads, Competitors, Accounts at Risk -->
      <div class="vx-row">
        <!-- Average Score -->
        <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
          <single-card
            icon="UsersIcon"
            smallTitle="Average Score"
            :totalValue="newLeads.StatEndValue"
            :bottomText="newLeads.StatStartValue + ' previous period'"
            :upDownValue="newLeads.StatChangePercent"
            bgcolor="bg-first-gradient"
            maintextcolor="text-first"
            bgimg="users.png"
          >
          </single-card>
        </div>
        <!-- Total Leads -->
        <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
          <single-card
            icon="UsersIcon"
            smallTitle="Total Leads"
            :totalValue="totalLeads.StatEndValue"
            :bottomText="totalLeads.StatStartValue + ' previous period'"
            :upDownValue="totalLeads.StatChangePercent"
            Upicon="ArrowDownIcon"
            textcolor="text-danger"
            bgcolor="bg-second-gradient"
            maintextcolor="text-second"
            bgimg="users.png"
          >
          </single-card>
        </div>
        <!-- Competitors-->
        <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
          <single-card
            icon="ListIcon"
            smallTitle="Competitors"
            :totalValue="competitors.StatEndValue"
            :bottomText="competitors.StatStartValue + ' previous period'"
            :upDownValue="competitors.StatChangePercent"
            Upicon="ArrowDownIcon"
            textcolor="text-danger"
            bgcolor="bg-third-gradient"
            maintextcolor="text-third"
            bgimg="list.png"
          >
          </single-card>
        </div>
        <!-- Accounts at Risk -->
        <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
          <single-card
            icon="EyeIcon"
            smallTitle="Accounts at Risk"
            :totalValue="accountsAtRisk.StatEndValue"
            :bottomText="accountsAtRisk.StatStartValue + ' previous period'"
            :upDownValue="accountsAtRisk.StatChangePercent"
            Upicon="ArrowDownIcon"
            textcolor="text-danger"
            bgcolor="bg-fourth-gradient"
            maintextcolor="text-fourth"
            bgimg="eye.png"
          >
          </single-card>
        </div>
      </div>
      <!-- Companies Showing the Most Interest, Distribution By Industry table -->
      <div class="vx-row">
        <!-- Companies Showing the Most Interest -->
        <div class="vx-col lg:w-1/2 col-cus-12 mb-8 w-full">
          <vx-card class="height-100">
            <div class="card-title more-card-title">
              <h2>Companies Showing the Most Interest</h2>
            </div>
            <div class="common-table intent-search-set pt-0">
              <vs-table :data="accountHighIntent" :max="5">
                <template slot="thead">
                  <vs-th sort-key="Account_Name"> Company Name</vs-th>
                  <vs-th>Employee Count</vs-th>
                  <vs-th>Sales Volume</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].Account_Name" >
                      {{ data[indextr].Account_Name }}
                    </vs-td>
                    <vs-td :data="data[indextr].Emp_Segment">
                      {{ data[indextr].Emp_Segment | tableRecordNumberFormatter }}
                    </vs-td>
                    <vs-td :data="data[indextr].Sales_Segment">
                      {{ data[indextr].Sales_Segment }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vx-card>
        </div>
        <!-- Distribution By Industry-->
        <div class="vx-col lg:w-1/2 col-cus-12 mb-8 w-full">
          <vx-card class="height-100">
            <div class="card-title more-card-title">
              <h2>Distribution By Industry</h2>
            </div>
            <div class="common-table intent-search-set pb-0 bradius-bottom pt-0">
              <vs-table :data="distributionByindustry" :max="5">
                <template slot="thead">
                  <vs-th>Industry</vs-th>
                  <vs-th sort-key="sortablePercent">% Of Total</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].SIC_Description">
                        {{ data[indextr].SIC_Description }}
                    </vs-td>
                    <vs-td :data="data[indextr].sortablePercent">
                          {{ data[indextr].sortablePercent }}
                    </vs-td>
                  </vs-tr>
                  <tfoot class="vs-table--tfoot">
                    <tr class="tr-values tr-table-state-null selected">
                      <td class="td vs-table--td">Total</td>
                      <td class="td vs-table--td">{{totalDistributionByIndustry.toFixed(2)}}%</td>
                    </tr>
                  </tfoot>
                </template>
              </vs-table>
            </div>
          </vx-card>
        </div>
      </div>
      <!-- Distribution By Total Employee, Distribution By Annual Sales charts -->
      <div class="vx-row">
        <!-- Distribution By Total Employee charts -->
        <div class="vx-col lg:w-1/2 col-cus-12 mb-8 w-full">
          <vx-card>
            <div class="card-title more-card-title">
              <h2>Distribution By Total Employee</h2>
            </div>
            <vue-apex-charts type="bar" :options="distributionByTotalEmployeeChart.chartOptions" :series="distributionByTotalEmployeeChart.series"></vue-apex-charts>
          </vx-card>
        </div>
        <!-- Distribution By Annual Sales charts -->
        <div class="vx-col lg:w-1/2 col-cus-12 mb-8 w-full">
          <vx-card>
            <div class="card-title more-card-title">
              <h2>Distribution By Annual Sales</h2>
            </div>
            <vue-apex-charts type="bar"  :options="distributionByAnnualSalesChart.chartOptions" :series="distributionByAnnualSalesChart.series"></vue-apex-charts>
          </vx-card>
        </div>
      </div>
    </div>
    <!-- if campaing not available  selected  -->
    <div v-else>
      <vx-card>
        <div class="vx-row ">
          <div class="vx-col  w-full text-center ">
            <div class="coming-soon sm:w-1/2 w-full py-5 mx-auto ">
              <img src="../../../src/assets/images/maintance.png" class="d-inline-block w-full"/>
              <router-link :to="{name: 'IntentCampaigns'}"><h4 class="text-primary py-5">Please configure a campaign to get started</h4></router-link>
              <!-- <router-link :to="{name: 'IntentCampaigns'}"><p class="btn-link"> Intent → Campaign page</p></router-link> -->
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <!-- end if campaing not available  selected  -->
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
/* Filter */
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import SingleCard from "../../components/SingleCard";
/* chart */
import VueApexCharts from "vue-apexcharts";

export default {
  title: "Dashboard",
  components: {
    VxCard,
    SelectFilter,
    DateRangePicker,
    SingleCard,
    VueApexCharts,
  },
  data() {
    return {
      campaignOptions: [""],
      camapign: {},

      fromDate: null,
      toDate: null,

      newLeads: {
        StatName: null,
        StatChangePercent: 0,
        StatEndValue: 0,
        StatStartValue: 0
      },

      totalLeads: {
        StatName: null,
        StatChangePercent: 0,
        StatEndValue: 0,
        StatStartValue: 0
      },

      competitors: {
        StatName: null,
        StatChangePercent: 0,
        StatEndValue: 0,
        StatStartValue: 0
      },

      accountsAtRisk: {
        StatName: null,
        StatChangePercent: 0,
        StatEndValue: 0,
        StatStartValue: 0
      },

      accountHighIntent: [],
      distributionByindustry: [],
      totalDistributionByIndustry: 0,

      distributionByTotalEmployeeChart: {
        series: [{
          name: "Employee",
          data: [0]
        }],
        chartOptions: {
          colors: "#044d80",
           chart: {
            toolbar: {
              show: false
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top'
              }
            }
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#bbbaba']
            },
            formatter: function(val) {
              return val+'%'
            },
            offsetX: 40
          },
          xaxis: {
            categories: [],
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          }
        }
      },
 
      distributionByAnnualSalesChart: {
        series: [{
          name: "Sales",
          data: [0]
        }],
        chartOptions: {
          chart: {
            toolbar: {
              show: false
            },
          },
          colors: "#044d80",
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top'
              }
            }
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#bbbaba']
            },
            formatter: function(val) {
              return val+'%'
            },
            offsetX: 40
          },
          xaxis: {
            categories: [],
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          }
        }
      }
    };
  },
  created() {
    this.getListOfCampagins()
    this.setFromDate();
    this.setToDate();
  },
  methods: {
    //Get list of campaign for search
    async getListOfCampagins() {
      this.$vs.loading();
      await this.axios.get("/ws/FindAnyoneCampaign/GetCampaigns").then((response) => {
        let data = response.data;
        this.campaignOptions = data;
        if(this.campaignOptions.length > 0){
          this.camapign = this.campaignOptions[0]
          this.updateData()
        }
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    //Search all data
    updateData(){
      let apiParams = {
        CampaignId: this.camapign.Id,
        Date_Start: this.fromDate,
        Date_End: this.toDate
      };
      this.getTotalLeads(apiParams)
      this.getAverageScore(apiParams)
      this.getCompetitors(apiParams)
      this.getAccountsAtRisk(apiParams)
      this.getAccountsHighIntent(apiParams)
      this.getDistributionByIndustry(apiParams)
      this.getDistributionByTotalEmployees(apiParams)
      this.getDistributionByAnnualSales(apiParams)
    },
    //Average Score
    getAverageScore(apiParams){
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneDashboard/GetAverageScore",apiParams).then(response => {
        let data = response.data;
        this.newLeads.StatName = data.StatName;
        this.newLeads.StatChangePercent = data.StatChangePercent;
        this.newLeads.StatEndValue = this.numberFormatter(data.StatEndValue);
        this.newLeads.StatStartValue = this.numberFormatter(data.StatStartValue);
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    //Total Leads
    getTotalLeads(apiParams){
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneDashboard/GetTotalLeads",apiParams).then(response => {
        let data = response.data;
        this.totalLeads.StatName = data.StatName;
        this.totalLeads.StatChangePercent = data.StatChangePercent;
        this.totalLeads.StatEndValue = this.numberFormatter(data.StatEndValue);
        this.totalLeads.StatStartValue = this.numberFormatter(data.StatStartValue);
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    //Competitors
    getCompetitors(apiParams){
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneDashboard/GetCompetitors",apiParams).then(response => {
        let data = response.data;
        this.competitors.StatName = data.StatName;
        this.competitors.StatChangePercent = data.StatChangePercent;
        this.competitors.StatEndValue = this.numberFormatter(data.StatEndValue);
        this.competitors.StatStartValue = this.numberFormatter(data.StatStartValue);
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    //Account at Risk
    getAccountsAtRisk(apiParams){
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneDashboard/GetAccountsAtRisk",apiParams).then(response => {
        let data = response.data;
        this.accountsAtRisk.StatName = data.StatName;
        this.accountsAtRisk.StatChangePercent = data.StatChangePercent;
        this.accountsAtRisk.StatEndValue = this.numberFormatter(data.StatEndValue);
        this.accountsAtRisk.StatStartValue = this.numberFormatter(data.StatStartValue);
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    //Companies Showing The Most Interest
    getAccountsHighIntent(apiParams){
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneDashboard/getAccountsHighIntent",apiParams).then(response => {
        let data = response.data;
        this.accountHighIntent = data
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    //Distribution By Industry
    getDistributionByIndustry(apiParams){
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneDashboard/getDistributionByIndustry",apiParams).then(response => {
        let data = response.data;
        this.distributionByindustry = data
        this.distributionByindustry.map(function(manipulatedData) {
          manipulatedData.sortablePercent = manipulatedData.Percent.toFixed(2)
          return manipulatedData;
        });
        this.totalDistributionByIndustry = 0
        for (let i = 0; i < this.distributionByindustry.length; i++) {
          this.totalDistributionByIndustry = this.totalDistributionByIndustry + this.distributionByindustry[i].Percent
        }
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    //Distribution By Total Employee
    getDistributionByTotalEmployees(apiParams){
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneDashboard/getDistributionByTotalEmployees", apiParams).then(response => {
        let data = response.data;
        let x = [];
        let FirstGroup = [];

        data.forEach(data => {
          x.push(data.Name);
          FirstGroup.push(parseFloat(data.Value.toFixed(2)));
        });

        if (!FirstGroup.length > 0) {
          FirstGroup = [0];
        }

        this.distributionByTotalEmployeeChart.series = [
          {
            name: "Employee",
            data: FirstGroup
          }
        ];

        this.distributionByTotalEmployeeChart.chartOptions = {...this.distributionByTotalEmployeeChart.chartOptions,...
          {
            xaxis: {
              categories: x
            }
          }
        };
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    //Distribution By Annual Sales
    getDistributionByAnnualSales(apiParams){
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneDashboard/getDistributionByAnnualSales", apiParams).then(response => {
        let data = response.data;
        let x = [];
        let FirstGroup = [];

        data.forEach(data => {
          x.push(data.Name);
          FirstGroup.push(parseFloat(data.Value.toFixed(2)));
        });

        if (!FirstGroup.length > 0) {
          FirstGroup = [0];
        }

        this.distributionByAnnualSalesChart.series = [
          {
            name: "Sales",
            data: FirstGroup
          }
        ];

        this.distributionByAnnualSalesChart.chartOptions = {...this.distributionByAnnualSalesChart.chartOptions,...
          {
            xaxis: {
              categories: x
            }
          }
        };
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    }
  },
};
</script>

